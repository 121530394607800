// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$green: #C4D600;
$red: #c41230;
$black: #000000;
$light-gray: #f5f5f5;
$blue: #CAE0E3;

body{
  font-family: 'Arimo', sans-serif !important;
  //font-family: 'Lato';
}

.fa-btn {
  margin-right: 6px;
}

nav.navbar {
  border-radius: 0;
  margin-bottom: 0;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #222;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1{
  font-family: 'Lora', serif !important;
  padding: 15px;
  color: #fff;
  border-radius: 4px;
}

a{
  color: $black;
}

iframe{
  min-width: 100%;
  max-width: 100%;
}

.bg-home{
  padding: 70px 0 70px;

  h1{
    color: #fff;
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
    //background: rgba(0,0,0,0.7);
    text-shadow: 2px 2px 4px #000000;
    padding: 15px;
  }
  
  .lead{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px #000000;
  }

  .btn-primary{
    border-color: white;
  }

  .btn-lg{
    padding: 20px 32px;
    font-size: 36px;
  }

}

@media (max-width: 480px)
{
  .bg-home{
    padding: 30px 0 30px;

    h1{
      font-size: 32px;
    }

    .btn-lg{
      font-size: 24px;
      padding: 10px 16px;
    }
  }
}

.bg-title{
  background: #ccc;
  margin-bottom: 30px;
}

.btn-primary{
  background: $red;
  border-color: $red;
  color: white;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary:active:focus,
.btn-primary.active:focus {
  background-color: $green;
  color: black;
  border-color: $black;
}

.header{
  padding-top: 15px;
}

.membership{
  background: #000;
  padding: 30px 0 30px;
  .lead{
    color: #fff;
  }
  p{
    margin: 0;
  }
}

.navbar{
  margin-bottom: 10px;
}

.navbar-inverse{
  color: $green;
}

.navbar-inverse {

  a{
    color: $blue !important;
  }

  a:hover{
    color: white !important;
  }

}

.navbar-inverse .navbar-toggle{
  border-color: $green;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus{
  background-color: $green;
  color: white;
}

.dropdown-menu{
  background: $green !important;

  li{
    a{
      color: #000 !important;

      &:hover{
        color: black !important;
      }
    }
  }
}

.panel-default>.panel-heading{
  background: $black;
  color: #fff;
  a{
    color: $green;
  }
  a:hover{
    text-decoration: none;
    color: #fff;
  }
}

.signup{
  background: $green;
  padding: 30px 0 30px;
}

.guest-logos{
  background: #f5f5f5;
  padding: 15px 0 15px;
}

.img-store-director{
  margin: 0 auto;
}

.navbar{
  .input-group{
    border: solid 1px $green;
    border-radius: 4px;
  }
}

.carousel-control.left, .carousel-control.right {
  background-image: none;
}

.carousel-control {
  width: 5%;
}

.home-section{
  padding-top: 70px;
  padding-bottom: 70px;

  h1{
    padding: 0;
    color: $black;
    font-size: 48px;
  }

  .lead{
    background: rgba(255,255,255,0.7);
    padding: 15px;
    border-radius: 4px;
  }
}

.weddings{

  .lead{
    background: #f5f5f5;
  }
}

.online-shopping{
  background: url('../img/backgrounds/vegetables.png') $green;
  background-attachment: fixed;
  background-size: cover;
}

.recipes-homepage{
  background: url('../img/backgrounds/recipes.jpg') $black;
  background-attachment: fixed;
  background-size: cover;
}

#survey-winner{
  margin: 15px 0 15px;

  .container{
    border: solid 1px $light-gray;
    padding: 30px 15px 15px 15px;

    .lead{
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}

#google-promo{
  background: url('../img/google-code-discount.jpg') center top;
  background-attachment: fixed;
  padding: 150px 0 100px;

  @media screen and (max-width: 480px)
  {
    padding: 50px 0 25px;
  }

  h1{
    text-align: center;
    background: rgba(0,0,0,0.5);
    font-family: 'Arimo', sans-serif !important;
    padding: 50px 0 30px;
  }

  @media screen and (max-width: 480px)
  {
    h1{
      font-size: 24px;
    }
  }

  .promo-code{
    font-size: 72px;
    font-weight: bold;
  }

  @media screen and (max-width: 480px)
  {
    .promo-code{
      font-size: 36px;
    }
  }

  p{
    text-align: center;
    background: $green;
    padding: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5em;
    border: solid 1px white;
  }
}

.coupon {
  border: 2px dashed black;
  width: 175px;
  margin: 15px;

  h3 {
    font-size: 1.3em;
  }

  .validity {
    background: black;
    color: white;
    display: block;
    text-align: center;
    font-size: .7em;
    font-weight: bold;
    border-radius: 3px;
  }

  .promo-desc {
    font-size: .8em;
  }
}

@media (max-width: 992px) {
  .coupon {
    width: 100%;
    font-size: .7em;
    margin-left: 0;
    margin-right: 0;
  }
}

.ticket-vendor {
  height: 150px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding-top: 15px;
  box-shadow: 1px 1px 1px #eee;
}

footer{
  background: $black;
  color: #fff;
  p{
    margin: 15px 0 15px;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.8em;
  }
  a{
    color: $green !important;
  }
}

.twelve-days {
  border: 1px solid black;
  background-color: #55AA55;
  height: 300px;
}
@media (min-width: 768px) {
  .twelve-days:nth-child(8n+2),
  .twelve-days:nth-child(8n+4),
  .twelve-days:nth-child(8n+5),
  .twelve-days:nth-child(8n+7) {
    background-color: #88CC88;
  }
}

@media (max-width: 480px){
  .guest-logos{
    img{
      margin-bottom: 10px;
    }
  }
  .img-store-director{
    max-width: 150px;
  }
}

@media (max-width: 767px){
  .img-store-director{
    max-width: 220px;
  }
  .twelve-days:nth-child(4n+1),
  .twelve-days:nth-child(4n+2) {
    background-color: #88CC88;
  }
}

/* Bootstrap Overrides */

@media screen and (max-width: 480px)
{
  .nav>li>a{
    padding-right: 12px;
  }
}

/* Utilities */

.g-recaptcha {
  max-width: 100%;
}

.sb-control {
  display: none;
}

/* PROMO Styles */

/* VALENTINES */

.vd-promo-container {
  background-color: #F0C1C8; height: 70px;
  border-bottom: 1px solid #333;
}
.vd-promo-h1 {
  font-family: 'Lora', serif !important;
  color: #333;
  font-size: 28px;
  padding: 15px;
}
.vd-promo-btn {
  color: #333;
  position: relative;
  bottom: 5px;
  margin-bottom: 15px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px;
  border: 1px solid #333;
}
.vd-promo-btn:hover, .vd-promo-btn:active {
  background-color: #333;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 862px){
  .vd-promo-container {
    height: 55px;
  }
  .vd-promo-h1 {
    font-size: 20px;
  }
}
@media (max-width: 767px){
  .vd-promo-container {
    height: 70px;
  }
  .vd-promo-h1 {
    font-size: 11px;
  }
  #vd-promo-header .vd-promo-btn {
    font-size: 12px;
    padding: 7px;
    margin-left: 5px;
  }
  #vd-promo-header .row {
    margin: 0 !important;
    padding: 0 !important;
  }
  #vd-promo-header .col-md-12 {
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 321px){
  .vd-promo-h1{
    font-size: 10px;
  }
}

#volunteers-container{
  background: url(/img/bg-volunteers.jpg);
  background-attachment: fixed;
  background-repeat: none;
  background-size: cover;
  padding: 80px 0 60px;
  color: #ffffff;

  h1{
    text-transform: uppercase;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !important;
    padding: 0;
    margin-bottom: 20px;
  }

  h3{
    text-transform: uppercase;
    border: solid 1px #ffffff;
    border-radius: 6px;
    padding: 15px 0 15px;
    font-weight: bold;
  }

  .bg-text{
    background: #c1d72e;
    padding: 30px 15px 30px 15px;
    border-radius: 6px;
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }

  @media screen and (max-width: 767px){
    .bg-text{
      font-size: 1.2em;
    }
  }

  form{
    background: rgba(0,0,0,0.4);
    padding: 15px;
    border-radius: 12px 0 12px 0;

    label{
      a{
        color: #00b3ee;
        text-decoration: underline;
      }
    }
  }

  .panel-body {
    color: #000000;
  }

  .modal{
    color: #000000;
  }
}

// Order Online Now promo bar

.oon-container {
  background-color: #C5E1E4;
  background-image: url(/img/shopping-cart.png);
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 15px
}
.oon-promo {
  color: black;
  margin: auto;
  width: 75%;
  padding-top: 5px;
}
.oon-left {
  float: left;
  padding-right: 15px;
  margin-top: -5px;
}
.oon-right {
  float: left;
  margin-top: -2px;
}
.oon-label {
  color: white;
  height: 50px;
  font-size: 24px;
  padding-left: 15px;
  position: relative;
  top: 5px;
}
.oon-btn {
  padding-left: 15px;
  margin-left: 15px;
  float: left;
}

@media (max-width: 767px) {
  .oon-container {
    padding: 15px 20px;
    background-image: none;
  }
  .oon-promo {
    width: auto;
  }
  .oon-right {
    padding-top: 20px;
  }
}

// Coupons carousel

.coupons {
  color: black;

  img {
    height: 75px;
    margin-right: 30px
  }

  .mobile-btn {
    margin-top: 15px;
  }
}

// Holiday Meals Top bar

.hm-days {
  height: 40px;
  background-color: #AD1A2D;
  color: #fff;
  font-family: 'Lora', serif;
  font-size: 18px;
  padding-top: 7px;
}
.hm-days a {
  color: #fff;
  text-decoration: underline
}

.hm-days a:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .hm-days {
    height: auto;
    font-size: 12px;
    padding-bottom: 10px;
  }
}

// 12 Days Landing Page

.td-container {
  font-family: Helvetica, Arial, sans-serif;
}

.td-banner {
  margin: 0 -15px 0 -15px;
}

.td-signup {
  background-color: #AD1A2D;
  border-bottom: 1px solid #821421;
  border-top: 1px solid #821421;
}

.td-next {
  color: white;
  font-size: 18px;
  margin-bottom: 0;
}

.td-next a {
  color: #fff;
  text-decoration: underline;
}

.td-gifts-header {
  padding: 15px;
  font-size: 36px;
  font-weight: bold;
  color: #777;
}

.td-deals-container {
  font-family: 'Lora', serif;
  padding-bottom: 20px;
}

.td-row {
  margin: auto;
  text-align: center;
}

.td-deal-col {
  display: inline-block;
  float: none;
  vertical-align: top;
  text-align: left;
  height: 500px;
  width: 250px;
  border: 1px solid #bbb;
  margin: 0 15px 30px 15px;
}

.td-deal-header {
  background-color: #bbb;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 -15px 0 -15px;
  padding: 5px;
}

.td-img {
  margin: 0 -15px 0 -15px;
  border-bottom: 1px solid #bbb;
}

.td-item-box {
  font-family: Helvetica, Arial, sans-serif;
  height: 200px;
  padding: 5px;
}

.td-item-name {
  font-size: 18px;
  font-weight: bold;
  color: #888;
}

.td-item-size {
  color: #999;
}

.td-item-price {
  font-size: 24px;
  font-weight: bold;
  color: #AD1A2D;
}

@media (max-width: 991px) {

  .td-next {
    font-size: 16px;
  }

}

@media (max-width: 767px) {

  .td-gifts-header {
    font-size: 26px;
  }

}

/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

// Loyalty

.loyalty-txt {
  position: relative; top: -39px; font-size: 24px; color: #999; background-color: #fff;
}

.loyalty-btns {
  margin-top: -25px; padding-bottom: 15px;
}